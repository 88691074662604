import format from 'date-fns/format'
import isValidDate from 'date-fns/is_valid'
import de from 'date-fns/locale/de'
import distanceInWordsFromNow from 'date-fns/distance_in_words_to_now'

export function dateTimeToGermanDate(dateTime) {
  if (!dateTime) {
    return ''
  }
  const date = new Date(dateTime)
  if (!isValidDate(date)) {
    throw new Error(`${dateTime} ist kein valides Datum`)
  }

  return format(date, 'DD.MM.YYYY')
}

export function fromNowDe(dateTime) {
  return distanceInWordsFromNow(dateTime, { locale: de })
}

export function toMonthYear(dateTime) {
  if (!dateTime) {
    return ''
  }

  const date = new Date(dateTime)
  if (!isValidDate(date)) {
    throw new Error(`${dateTime} ist kein valides Datum`)
  }

  return format(date, 'MMMM YYYY', { locale: de })
}
