<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    lazy
    transition="scale-transition"
    offset-y
    full-width
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        :value="textFieldValue"
        :label="label"
        readonly
        :hide-details="hideDetails"
        :disabled="disabled"
        append-icon="event"
        box
        v-on="!disabled && on"
      />
    </template>
    <v-date-picker
      :value="value"
      @input="setDate"
      locale="de-de"
      :allowed-dates="allowedDates"
      :first-day-of-week="1"
      :readonly="disabled"
      :disabled="disabled"
    />
  </v-menu>
</template>

<script>
import { dateTimeToGermanDate } from '@/utils/DateHelper'

export default {
  name: 'Datepicker',
  props: ['value', 'label', 'allowedDates', 'disabled', 'hideDetails'],
  data() {
    return {
      menu: false,
    }
  },
  computed: {
    textFieldValue() {
      try {
        return dateTimeToGermanDate(this.value)
      } catch (e) {
        return ''
      }
    },
  },
  methods: {
    setDate(newDate) {
      this.menu = false
      this.$emit('input', newDate)
    },
  },
}
</script>

<style scoped></style>
